import { SpacingCard, TimeoutCard } from "../components/Widgets";
import Brands from "../components/Widgets/Brands";
import Category from "../components/Widgets/Category";
import ContentEditor from "../components/Widgets/ContentEditor";
import MainSlider from "../components/Widgets/MainSlider";
import PopulerProduct from "../components/Widgets/PopulerProduct";
import ProductRow from "../components/Widgets/ProductRow";
import ProductSlider from "../components/Widgets/ProductSlider";
import ProductsRowLimit from "../components/Widgets/ProductsRowLimit";
import SingleProduct from "../components/Widgets/SingleProducts";
import ThreeCollumsImages from "../components/Widgets/ThreeCollumsImages";
import Title from "../components/Widgets/Title";
import { LogicalOrderTypes } from "./../components/LogicBuilder/LogicalBuilder/index";
import {
  FilterProducts,
  RandomBanners,
  ServicesBanner,
  SmallWideBanner,
  ThreeBannersProducts,
  TrendingProducts,
} from "./../components/Widgets/Banner/index";
import { LinkData } from "./../components/widgetFrom/LinkForm/index";

export interface Image {
  image: string;
  title: string;
  description?: string;
  link: LinkData;
}
export interface LogicalObj {
  id: number;
  limit: string;
  name?: string;
  avatar?: string;
}
export interface Logical {
  category: LogicalObj[];
  categoryOrder?: LogicalOrderTypes;
  brand: LogicalObj[];
  brandOrder?: LogicalOrderTypes;
  seller: LogicalObj[];
  sellerOrder?: LogicalOrderTypes;
  campaign: LogicalObj[];
  campaignOrder?: LogicalOrderTypes;

  isFeatured?: boolean;
}
export interface PageCategory {
  id: number;
  name: string;
  slug: string;
}
export interface IndemandObj {
  image: string;
  title: string;
  subTitle: string;
  link: LinkData;
}
export interface Widget {
  images?: string;
  wType?: "widgets" | "sidebar" | "topbar";
  name: string;
  id?: string;
  Component?: React.FC<any>;
  monarchmart_sku?: string;
  title?: string;
  link?: LinkData;
  active: boolean;
  data?: {
    images?: Image[];
    selectedCampaign?: PageCategory | null;
    showTimer?: boolean;
    setting?: {
      custom?: number[];
      logical?: Logical;
      productType?: "";
    };
    settings?: any;
    products?: [];
    featuredProduct?: {};
    shops?: [];
    bestSellingProducts?: {
      products: [];
      featuredImage: {
        image: "";
        title: "";
        url: LinkData;
        target: "";
      };
    };
    singleProductSlider?: {
      products: [];
    };
    electronicsApplience?: {
      productTypes: [];
      featuredImage: {
        image: "";
        title: "";
        url: "";
        target: "";
      };
    };
    sellerProfile?: {
      campaignImages: [];
      coverImage: "";
      profileImage: "";
    };
    content?: "";
    rowLimit?: string;
    isSlider?: boolean;
    backgroundColor?: string;
    inDemandObj?: {
      inDemandObjOne?: {
        image: "";
        title: "";
        subTitle: "";
        link: {};
      };
      inDemandObjTwo?: {
        image: "";
        title: "";
        subTitle: "";
        link: {};
      };
      inDemandObjThree?: {
        image: "";
        title: "";
        subTitle: "";
        link: {};
      };
      inDemandObjFour?: {
        image: "";
        title: "";
        subTitle: "";
        link: {};
      };
    };
    rightImages?: {
      rightImageOne?: {
        image: "";
        title: "";
        link: {};
      };
      rightImageTwo?: {
        image: "";
        title: "";
        link: {};
      };
      rightImageThree?: {
        image: "";
        title: "";
        link: {};
      };
    };
  };
  imageLimit?: number;
  featuredImage?: string;
  fetchuredImageUrl?: string | LinkData;
  fetcturedImagePosition?: string;
  fetcturedImagelinkTarget?: string;
  category?: any[];
  brandList?: any[];
  sellerList?: number[];
  productLimit?: string;
  rightImages?: Image[];
  rightImagesPosition?: string;
}

export const widgetNames = {
  lastVisitedProducts: "last-visited-products",
  infiniteProductRow: "infinite-product-row",
  smallWideBanner: "small-wide-banner",
  banners: "banners",
  trendingProducts: "trending-products",
  filterProducts: "filter-products",
  randomSizeBanners: "random-size-banners",
  threeBannerProducts: "three-banner-products",
  services: "services",
  padding: "padding",
  timeout: "timeout",
};

export const widgetData = {
  title: {
    name: "title",
    active: true,
    title: "",
    link: {} as LinkData,
    Component: Title,
  },
  slider: {
    name: "slider",
    active: true,
    data: {} as any,
    Component: MainSlider,
  },
  [widgetNames.banners]: {
    name: widgetNames.banners,
    title: "Banners",
    active: true,
    Component: ThreeCollumsImages,
  },
  "populer-product": {
    name: "populer-product",
    title: "title here",
    active: true,
    imageLimit: 1,
    featuredImage: "",
    fetchuredImageUrl: "",
    fetcturedImagePosition: "left",
    fetcturedImagelinkTarget: "",
    data: {} as any,
    Component: PopulerProduct,
  },
  categories: {
    name: "categories",
    active: true,
    category: [],
    Component: Category,
  },
  "product-row": {
    name: "product-row",
    active: true,
    productLimit: "",
    Component: ProductRow,
  },
  "brand-list": {
    name: "brand-list",
    active: true,
    brandList: [],
    Component: Brands,
  },
  "product-slider": {
    name: "product-slider",
    title: "title here",
    active: true,
    data: {} as any,
    Component: ProductSlider,
  },
  [widgetNames.trendingProducts]: {
    name: widgetNames.trendingProducts,
    title: "Trending Products",
    active: true,
    Component: TrendingProducts,
  },
  [widgetNames.filterProducts]: {
    name: widgetNames.filterProducts,
    title: "Filter Tab Products",
    active: true,
    Component: FilterProducts,
  },
  [widgetNames.randomSizeBanners]: {
    name: widgetNames.randomSizeBanners,
    title: "Random Size Banners",
    active: true,
    Component: RandomBanners,
  },
  [widgetNames.threeBannerProducts]: {
    name: widgetNames.threeBannerProducts,
    title: "Three Banner Products",
    active: true,
    Component: ThreeBannersProducts,
  },
  "single-product": {
    name: "single-product",
    title: "",
    active: true,
    data: {} as any,
    Component: SingleProduct,
  },
  "content-editor": {
    name: "content-editor",
    title: "",
    active: true,
    data: {} as any,
    Component: ContentEditor,
  },
  "product-row-limit": {
    name: "product-row-limit",
    title: "",
    active: true,
    data: {} as any,
    Component: ProductsRowLimit,
  },
  [widgetNames.lastVisitedProducts]: {
    name: widgetNames.lastVisitedProducts,
    title: "Last Visited Products",
    active: true,
    data: {} as any,
    Component: ProductsRowLimit,
  },
  [widgetNames.smallWideBanner]: {
    name: widgetNames.smallWideBanner,
    title: "Small & Wide Banner",
    active: true,
    Component: SmallWideBanner,
  },
  [widgetNames.services]: {
    name: widgetNames.services,
    title: "Services",
    active: true,
    Component: ServicesBanner,
  },
  [widgetNames.padding]: {
    name: widgetNames.padding,
    title: "Spacing",
    active: true,
    Component: SpacingCard,
  },
  [widgetNames.timeout]: {
    name: widgetNames.timeout,
    title: "Timeout",
    active: true,
    Component: TimeoutCard,
  },
};

export const widgets: Widget[] = [
  widgetData.title,
  widgetData.slider,
  widgetData[widgetNames.banners],
  widgetData["populer-product"],
  widgetData.categories,
  widgetData["product-row"],
  widgetData["brand-list"],
  widgetData["product-slider"],
  widgetData[widgetNames.trendingProducts],
  widgetData[widgetNames.filterProducts],
  widgetData[widgetNames.randomSizeBanners],
  widgetData[widgetNames.threeBannerProducts],
  widgetData["single-product"],
  widgetData["content-editor"],
  widgetData["product-row-limit"],
  widgetData[widgetNames.lastVisitedProducts],
  widgetData[widgetNames.smallWideBanner],
  widgetData[widgetNames.services],
  widgetData[widgetNames.padding],
  widgetData[widgetNames.timeout],
];

export const webWidgets: Widget[] = [
  widgetData.title,
  widgetData.slider,
  widgetData[widgetNames.banners],
  widgetData["populer-product"],
  widgetData.categories,
  widgetData["product-row"],
  widgetData["brand-list"],
  widgetData["product-slider"],
  widgetData[widgetNames.trendingProducts],
  widgetData[widgetNames.filterProducts],
  widgetData[widgetNames.randomSizeBanners],
  widgetData[widgetNames.threeBannerProducts],
  widgetData["single-product"],
  widgetData["content-editor"],
  widgetData["product-row-limit"],
  widgetData[widgetNames.lastVisitedProducts],
  widgetData[widgetNames.smallWideBanner],
  widgetData[widgetNames.services],
  widgetData[widgetNames.padding],
];

export const mobileWidgets: Widget[] = [
  widgetData.title,
  widgetData.slider,
  widgetData[widgetNames.banners],
  widgetData["populer-product"],
  widgetData.categories,
  widgetData["product-row"],
  widgetData["brand-list"],
  widgetData["product-slider"],
  widgetData[widgetNames.trendingProducts],
  widgetData[widgetNames.filterProducts],
  widgetData[widgetNames.randomSizeBanners],
  widgetData[widgetNames.threeBannerProducts],
  widgetData["single-product"],
  widgetData["content-editor"],
  widgetData["product-row-limit"],
  widgetData[widgetNames.lastVisitedProducts],
  widgetData[widgetNames.smallWideBanner],
  widgetData[widgetNames.services],
  widgetData[widgetNames.padding],
];
