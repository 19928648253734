import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ClassicEditor,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  BlockToolbar,
  Bold,
  Bookmark,
  Code,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Markdown,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  ShowBlocks,
  SimpleUploadAdapter,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Title,
  TodoList,
  Underline,
  WordCount,
  Style,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

const LICENSE_KEY = "GPL";

export default function CkEditor({
  value,
  onEventChangeHandler,
}: {
  value?: string;
  onEventChangeHandler: (value: string, editor?: any) => void;
}) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const editorWordCountRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const { editorConfig } = useMemo(() => {
    if (!isLayoutReady) {
      return {};
    }

    return {
      editorConfig: {
        toolbar: {
          items: [
            "sourceEditing",
            "showBlocks",
            "|",
            "heading",
            "style",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "code",
            "removeFormat",
            "|",
            "specialCharacters",
            "horizontalLine",
            "pageBreak",
            "link",
            "bookmark",
            "insertImage",
            "insertTable",
            "highlight",
            "blockQuote",
            "codeBlock",
            "htmlEmbed",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: true,
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          BlockToolbar,
          Bold,
          Bookmark,
          Code,
          CodeBlock,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          FullPage,
          GeneralHtmlSupport,
          Heading,
          Highlight,
          HorizontalLine,
          HtmlComment,
          HtmlEmbed,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          Style,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Markdown,
          PageBreak,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          ShowBlocks,
          SimpleUploadAdapter,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Title,
          TodoList,
          Underline,
          WordCount,
        ],
        balloonToolbar: [
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "|",
          "bulletedList",
          "numberedList",
        ],
        blockToolbar: [
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "insertImage",
          "insertTable",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
        ],
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
        licenseKey: LICENSE_KEY,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        placeholder: "Type or paste your content here!",
        style: {
          definitions: [
            {
              name: "Article category",
              element: "h3",
              classes: ["category"],
            },
            {
              name: "Title",
              element: "h2",
              classes: ["document-title"],
            },
            {
              name: "Subtitle",
              element: "h3",
              classes: ["document-subtitle"],
            },
            {
              name: "Info box",
              element: "p",
              classes: ["info-box"],
            },
            {
              name: "Side quote",
              element: "blockquote",
              classes: ["side-quote"],
            },
            {
              name: "Marker",
              element: "span",
              classes: ["marker"],
            },
            {
              name: "Spoiler",
              element: "span",
              classes: ["spoiler"],
            },
            {
              name: "Code (dark)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-dark"],
            },
            {
              name: "Code (bright)",
              element: "pre",
              classes: ["fancy-code", "fancy-code-bright"],
            },
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
      },
    };
  }, [isLayoutReady]);

  const handleSourceEditingToggle = (editor: any) => {
    const sourceEditing = editor?.plugins?.get("SourceEditing");

    sourceEditing.on(
      "change:isSourceEditingMode",
      (_eventInfo: unknown, _name: string, value: boolean) => {
        if (value) {
          // Get the textarea
          const sourceEditingTextarea =
            editor.editing.view.getDomRoot()?.nextSibling?.firstChild;

          if (!sourceEditingTextarea) {
            throw new Error("This should not be possible");
          }

          // Add input event listener
          // -> Must be used because it appears the `change` event is being prevented
          sourceEditingTextarea.addEventListener("input", () => {
            // Trigger the editor's data to update
            sourceEditing.updateEditorData();
          });
        }
      }
    );
  };

  return (
    <div className="main-container">
      <div
        className="editor-container editor-container_classic-editor editor-container_include-block-toolbar editor-container_include-word-count"
        ref={editorContainerRef}
      >
        <div className="editor-container__editor">
          <div ref={editorRef}>
            {editorConfig && (
              <CKEditor
                editor={ClassicEditor as any}
                config={editorConfig as any}
                data={value}
                // onChange={(event, editor: any) => {
                //   const data = editor?.getData();
                //   onEventChangeHandler(data, editor);
                // }}
                onReady={(editor) => {
                  handleSourceEditingToggle(editor);
                }}
                onChange={(event, editor) => {
                  handleSourceEditingToggle(editor);

                  const data = editor?.getData();

                  onEventChangeHandler(data, editor);
                }}
              />
            )}
          </div>
        </div>
        <div
          className="editor_container__word-count"
          ref={editorWordCountRef}
        ></div>
      </div>
    </div>
  );
}

// const TinyMCE = ({ value, onEventChangeHandler }: tinyProps) => {
//   const uploadAdapter = (loader: FileLoader): UploadAdapter => {
//     return {
//       upload: () => {
//         return new Promise(async (resolve, reject) => {
//           try {
//             const file = await loader.file;

//             if (file) {
//               const formData = new FormData();

//               formData.append("file", file);

//               axios
//                 .post(`${process.env.REACT_APP_MEDIA_URL}`, formData, {
//                   headers: {
//                     "Content-Type": "multipart/form-data",
//                     Authorization: `Bearer ${Cookies.get(
//                       "bitcommerz-sass-admin-token"
//                     )}`,
//                   },
//                 })
//                 .then(({ data }) => {
//                   resolve({
//                     default: `${process.env.REACT_APP_IMAGE_URL}${data.path}`,
//                   });
//                 });
//             }
//           } catch (error) {
//             reject("Hello");
//           }
//         });
//       },
//       abort: () => {},
//     };
//   };

//   function uploadPlugin(editor: any) {
//     editor.plugins.get("FileRepository").createUploadAdapter = (
//       loader: any
//     ) => {
//       return uploadAdapter(loader);
//     };
//   }

//   //   toolbar: 'bold italic underline | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | code |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | undo redo',

//   return (
//     <div>
//       <CKEditor
//         editor={ClassicEditor}
//         data={value}
//         onReady={(editor) => {
//           // You can store the "editor" and use when it is needed.
//           console.log("Editor is ready to use!", editor);
//         }}
//         config={
//           {
//             // toolbar: toolbarOptions,
//             mediaEmbed: {
//               previewsInData: true,
//             },
//             extraPlugins: [uploadPlugin],

//             heading: {
//               options: [
//                 {
//                   model: "paragraph",
//                   title: "Paragraph",
//                   class: "ck-heading_paragraph",
//                 },
//                 {
//                   model: "heading1",
//                   view: "h1",
//                   title: "Heading 1",
//                   class: "ck-heading_heading1",
//                 },
//                 {
//                   model: "heading2",
//                   view: "h2",
//                   title: "Heading 2",
//                   class: "ck-heading_heading2",
//                 },
//                 {
//                   model: "heading3",
//                   view: "h3",
//                   title: "Heading 3",
//                   class: "ck-heading_heading3",
//                 },
//                 {
//                   model: "heading4",
//                   view: "h4",
//                   title: "Heading 4",
//                   class: "ck-heading_heading4",
//                 },
//                 {
//                   model: "heading5",
//                   view: "h5",
//                   title: "Heading 5",
//                   class: "ck-heading_heading5",
//                 },
//                 {
//                   model: "heading6",
//                   view: "h6",
//                   title: "Heading 6",
//                   class: "ck-heading_heading6",
//                 },
//               ],
//             },
//             link: {
//               // addTargetToExternalLinks: true,
//               // allowCreatingEmptyLinks: true,
//               defaultProtocol: "https://",
//               decorators: {
//                 isExternal: {
//                   mode: "manual",
//                   label: "Open in a new tab",
//                   attributes: {
//                     target: "_blank",
//                   },
//                 },
//                 isExtendedWithNoFollow: {
//                   mode: "manual",
//                   label: "Nofollow",
//                   attributes: {
//                     rel: "noopener noreferrer nofollow",
//                   },
//                 },
//                 isDownloadable: {
//                   mode: "manual",
//                   label: "Downloadable",
//                   attributes: {
//                     download: "file.png",
//                   },
//                 },
//               },
//             },
//           } as any
//         }
//         onChange={(event, editor: any) => {
//           const data = editor?.getData();
//           onEventChangeHandler(data, editor);
//         }}
//         onBlur={(event, editor) => {
//           console.log("Blur.", editor);
//         }}
//         onFocus={(event, editor) => {
//           console.log("Focus.", editor);
//         }}
//       />
//     </div>
//   );
// };

// export default TinyMCE;
