import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Widget } from "../../../widgetList/widgetList";
import SettingFormItems, {
  SettingItem,
  SettingItems,
} from "../../SettingFormItems";

interface Props {
  choosenItem: Widget;
  widgetName: string;
  onUpdate: (w: Widget) => void;
}

type Panel = "main" | "settings";
const panels: {
  name: Panel;
  title: string;
}[] = [
  {
    name: "main",
    title: "Main",
  },
  // {
  //   name: "settings",
  //   title: "Settings",
  // },
];

type SettingsNames =
  | "py"
  | "width"
  | "height"
  | "fullvh"
  | "overlay"
  | "overlayOpacity";

const FormForTimeout: FC<Props> = ({ choosenItem, widgetName, onUpdate }) => {
  const choosenAllImages = useSelector(
    (state: RootState) => state.showImagePopUp.images
  );

  const [active, setActive] = useState<boolean>(choosenItem.active);

  const [endDate, setEndDate] = useState(
    (choosenItem.data as any)?.timeoutDate || ""
  );

  const [countDownTitle, setCountDownTitle] = useState<string>(
    (choosenItem.data as any)?.countDownTitle || ""
  );

  const [panel, setPanel] = useState<Panel>("main");
  const [containerType, setContainerType] = useState<"full" | "box">(
    (choosenItem as any)?.data?.settings?.containerType || "box"
  );
  const [settings, setSettings] = useState<SettingItems<SettingsNames>>(
    (choosenItem as any)?.data?.settings as any
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const widgetObj = {
      name: choosenItem.name,
      active: active,
      data: {
        timeoutDate: endDate,
        countDownTitle,
        settings: {
          ...settings,
          containerType: containerType,
        },
      },
      wType: choosenItem.wType,
      id: choosenItem.id,
    };
    onUpdate(widgetObj as any);
  };

  return (
    <form onSubmit={handleSubmit} className="mainSliderForm__container">
      <h1 className="title">{widgetName}</h1>
      {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

      {/* panels switch */}
      <div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
        {panels.map((p) => (
          <button
            key={p.name}
            type="button"
            className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
              panel === p.name
                ? "border-green-500 text-black"
                : "text-black/50 hover:text-black/70"
            }`}
            onClick={() => setPanel(p.name)}
          >
            {p.title}
          </button>
        ))}
      </div>

      {panel === "main" && (
        <div>
          <div>
            <div className="capitalize">Title</div>
            <input
              className="mb-1"
              value={countDownTitle}
              type="text"
              placeholder="STARTING IN"
              onChange={(e) => setCountDownTitle(e.target.value)}
            />
          </div>

          <div>
            <div className="capitalize">Timeout Date</div>
            <input
              className="mb-1"
              value={endDate}
              type="date"
              placeholder="End Date :"
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      )}

      {/* {panel === "settings" && (
        <div>
          <div className="flex flex-col">
            <div>Image container: </div>
            <div className="[&>input]:!w-4 [&>input]:mx-2">
              <input
                type="radio"
                id="b-type-full"
                name="imageContainer"
                value="full"
                onChange={(e) =>
                  setContainerType(e.target.value as "full" | "box")
                }
                checked={containerType === "full"}
              />
              <label htmlFor="b-type-full">Full</label>

              <input
                type="radio"
                id="b-type-box"
                name="imageContainer"
                value="box"
                onChange={(e) =>
                  setContainerType(e.target.value as "full" | "box")
                }
                checked={containerType === "box"}
              />
              <label htmlFor="b-type-box">Box</label>
            </div>
          </div>

          <SettingFormItems
            settings={settings}
            title="Timeout"
            settingItems={settingItems}
            onChange={(key, value) => {
              setSettings((prev) => ({
                ...prev,
                [key]: value,
              }));
            }}
          />
        </div>
      )} */}
      <div>
        <button className="mainSliderForm__btn">save</button>
      </div>
    </form>
  );
};

export default FormForTimeout;
